import React,{Fragment, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Modal, Card, Badge, Table, Col} from 'react-bootstrap';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import swal from "sweetalert";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getDatabase, ref, onValue } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import {addTodo, deleteTodo} from '../../../store/actions/index';
import {useSelector, useDispatch} from 'react-redux';
//import ImageBlog from '../Fasto/Contacts/ImageBlog';
import ContactsData from '../Fasto/Contacts/ContactsData';
import { onSnapshot, getFirestore, collection, query, updateDoc, addDoc, deleteDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
//Images
import user from './../../../images/contacts/user.jpg';
import { getStorage } from '../../../services/AuthService';


const Analytics = () =>{

	const svg1 = (
		<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
		  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<rect x="0" y="0" width="24" height="24"></rect>
			<circle fill="#000000" cx="5" cy="12" r="2"></circle>
			<circle fill="#000000" cx="12" cy="12" r="2"></circle>
			<circle fill="#000000" cx="19" cy="12" r="2"></circle>
		  </g>
		</svg>
	  );
	const [me, setMe] = useState([])
	const [pending, setPending] = useState([])
	const [selectedOption, setSelectedOption] = useState(null);
	const [allCompanies, setAllCompanies] = useState([])
	const [dropdownCompanies, setDropDownCompanies] = useState([])
	const db = getDatabase();
	let companies = [];
	let dropdown = [];

	function getUniqueListBy(arr, key) {
		return [...new Map(arr.map(item => [item[key], item])).values()]
	}
	const getCompanies = (val) => {
		setDropDownCompanies([])
		const starCountRef = ref(db, 'companies');
		onValue(starCountRef, (snapshot) => {	
		snapshot.forEach(data => {
			const dataVal = data.val()
			if (dataVal.clientOf === val.companyId) {
				dropdown.push({
					value: data.key,
					label: dataVal.company,
				})
			}
			
		})
		const arr2 = getUniqueListBy(dropdown, 'value')
		setDropDownCompanies(arr2)
		});
	}

	const getClients = (authedUser) => {
		setAllCompanies([])
		let currentUserComp;
		const userRef = ref(db, 'users');
		onValue(userRef, (snapshot) => {	
		snapshot.forEach(data => {
			const dataVal = data.val()
			if (dataVal.phone === authedUser) {
				console.log(dataVal)
				setMe(dataVal)
				getCompanies(dataVal)
				currentUserComp = dataVal.companyId
			}
			if (dataVal.proUser === true) {
				companies.push({
					id: data.key,
					full_name: dataVal.full_name,
					clientOf: dataVal.clientOwnerId,
					clientName: dataVal.clientOwnerName,
					company: dataVal.company
				})
				
			}
		})
		const arr1 = getUniqueListBy(companies, 'id')
		const onlyCompUsers = arr1.filter((i) => i.clientOf === currentUserComp)
		setAllCompanies(onlyCompUsers)
		});
	}

	  useEffect(() => {
		const authedUser = getStorage()
		console.log(authedUser)
		getClients(authedUser)
	  }, [])
	//tab function 
	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	
	// This is Model function
	const [addContact, setAddContact] = useState(false);
	const [addCompany, setAddCompany] = useState(false)
	
	//Remove and delete List from Blog 'function'
	const [data, setData] = useState([]);

	//Add and delete data from list
	const dispatch = useDispatch();	
    let list = useSelector((state) => state.todoReducers.list);
	const [list_name, setInputData] = useState('');
	const [list_occupation, setInputData1] = useState('');
	const [email, setEmail] = useState('')
	const [lastName, setLastName] = useState('')
	const [companyName, setCompanyName] = useState('')

	//Error message 
	function onAddData(e){
		e.preventDefault();
		var error = false;
		var errorMsg = '';
		if(list_name === ''){
			error = true;
			errorMsg = 'Please fill out first name.';
		}else if(list_occupation === ''){
			error = true;
			errorMsg = 'Please fill out title.';
		}else if(email === ''){
			error = true;
			errorMsg = 'Please fill out email.';
		} else if (lastName === '') {
			error = true;
			errorMsg = 'Please fill out last name.';
		}
	  
		if(!error){

				const user = {
					// email: email,
					phone: list_occupation,
					full_name: list_name + ' ' +lastName,
					// clientOwner: 'tikkit',
					// company: '',
				}

				const functions = getFunctions();
				const addUser = httpsCallable(functions, 'api_user-createDBProUser');
				const updateUser = httpsCallable(functions, 'api_user-updateProUser');

				addUser({ user })
				.then((result) => {
					// Read result of the Cloud Function.
					/** @type {any} */
					// const data = result.data;
					console.log(result.data.data)
					const userUpdate = {
						email: email,
						full_name: list_name + ' ' +lastName,
						clientOwnerId: me.companyId,
						clientOwnerName: me.company,
						company: selectedOption.label,
						companyId: selectedOption.value,
						uid: result.data.data
					}
					updateUser({ user: userUpdate })
					.then((result) => {
						console.log(result)
					});
				});
			
			
            setInputData('');
            setInputData1('');
			setAddContact(false);
			swal('Good job!', 'Successfully Added', "success");
		}else{
			swal('Oops', errorMsg, "error");
		}
    }

	function onAddCompany(e){
		e.preventDefault();
		var error = false;
		var errorMsg = '';
		if(companyName === ''){
			error = true;
			errorMsg = 'Please fill out company name.';
		}
	  
		if(!error){

				const user = {
					company: companyName,
					clientOf: me.companyId
				}

				const functions = getFunctions();
				const addMessage = httpsCallable(functions, 'api_user-createCompanyPro');
				addMessage({ user })
				.then((result) => {
					// Read result of the Cloud Function.
					/** @type {any} */
					// const data = result.data;
				});
            setCompanyName('');
			setAddCompany(false);
			swal('Good job!', 'Successfully Added', "success");
		}else{
			swal('Oops', errorMsg, "error");
		}
    }

	const handleRemove = () => {

	}


	console.log(me)
	
	return(
		<Fragment>
			<div className="project-nav">
				<Col lg={12}>
				<Card>
            <Card.Header>
              <Card.Title>Analytics</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="width80">
                      <strong>#</strong>
                    </th>
                    <th>
                      <strong>Client</strong>
                    </th>
                    <th>
                      <strong>Total Events</strong>
                    </th>
                    <th>
                      <strong>Total Tickets Sold</strong>
                    </th>
                    <th>
                      <strong>Total Guest list</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>01</strong>
                    </td>
                    <td>SLU</td>
                    <td>10</td>
                    <td>1500</td>
                    <td>
                      1000
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>02</strong>
                    </td>
                    <td>Texas A&M</td>
                    <td>15</td>
                    <td>3500</td>
                    <td>
                      2500
                    </td>
                    
                  </tr>
                  <tr>
                    <td>
                      <strong>03</strong>
                    </td>
                    <td>CPC</td>
                    <td>2</td>
                    <td>4000</td>
                    <td>
                      100
                    </td>
                    
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
		  </Col>
			</div>			
		</Fragment>
	)
}
export default Analytics;