import React from "react";
import Index from './jsx/index';
import {  Route, Switch, withRouter } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import { initializeApp } from "firebase/app"

import "./css/style.css";

//const Home = lazy(() => import('./jsx/components/Dashboard/Index'));
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => import('./jsx/pages/Login'));


function App(props) {
    const dispatch = useDispatch();

    useEffect(() => {
		const firebaseApp = initializeApp({
			apiKey: "AIzaSyAYMVjkGzIXPkQkPJTanwTfsraaIzeyXQI",
			authDomain: "tikkit-development.firebaseapp.com",
			databaseURL: "https://tikkit-development.firebaseio.com",
			projectId: "tikkit-development",
			storageBucket: "tikkit-development.appspot.com",
			messagingSenderId: "771022102495",
			appId: "1:771022102495:web:af9024cf9eed4d6487ae6a",
			measurementId: "G-JD536GMBYH"
		  });
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);

    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
        </Switch>
    );

	if(props.isAuthenticated) {
		return(
			<div>
				
				<Suspense fallback={
					{/* <div id="preloader">
						<div className="waviy">
						   <span style={{'--i': 1}}>L</span>
						   <span style={{'--i': 2}}>o</span>
						   <span style={{'--i': 3}}>a</span>
						   <span style={{'--i': 4}}>d</span>
						   <span style={{'--i': 5}}>i</span>
						   <span style={{'--i': 6}}>n</span>
						   <span style={{'--i': 7}}>g</span>
						   <span style={{'--i': 8}}>.</span>
						   <span style={{'--i': 9}}>.</span>
						   <span style={{'--i': 10}}>.</span>
						</div>
					</div>  */}
				}
				>
				<Index / >
			</Suspense>
			</div>
		);
	
	}else{
		 return (
			<>
				<Suspense fallback={
					<div id="preloader">
							<div className="waviy">
							   <span style={{'--i': 1}}>Loading..</span>
							</div>
						</div> 
					}
				>
					{routes}
				</Suspense>
			</>
		);
	} 
}

const mapStateToProps = (state) => {
	return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));  