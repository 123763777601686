import React,{ useEffect, useMemo, useState } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from './MOCK_DATA_2.json';
import { COLUMNS } from './Columns';
import {Button,Tab, Nav, Row} from 'react-bootstrap';
import { getDatabase, ref, onValue, equalTo, orderByChild, query } from "firebase/database";
import Switch from "react-switch";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, getUserDetailsStorage } from '../../../../services/AuthService';
import { GlobalFilter } from './GlobalFilter'; 
import './filtering.css';


export const Checkin = (props) => {
	const tabData = [
		{
		  nameOfTab: "With Ticket",
		},
		{
		  nameOfTab: "All",
		},
	  ];
	const [fullData, setFullData] = useState([])
	const [loading, setLoading] = useState(false)
	const [cpc, setCpc] = useState(false)
	const [currentTab, setCurrentTab] = useState("With Ticket")
	const [userThatCheckedIn, setUserThatCheckIn] = useState([]) 
	const db = getDatabase();
	let cpcData = [];
	let checkinData = [];
	function getUniqueListBy(arr, key) {
		return [...new Map(arr.map(item => [item[key], item])).values()]
	}
	const getGuestList = (val) => {
		const starCountRef = query(ref(db, 'cpc'))
		onValue(starCountRef, async (snapshot) => {	
		snapshot.forEach(data => {
			const dataVal = data.val()
			cpcData.push(dataVal)
			
 		})

		 const checkinRef = query(ref(db, `checkedin/${props.match.params.id}`))
		 onValue(checkinRef, async (snapshot) => {	
		 snapshot.forEach(data => {
			 const checkinUser = {
				 key: data.key,
				 isCheckedIn: data.val()
			 }
			 checkinData.push(checkinUser)
			 
		  })
		
		setUserThatCheckIn(checkinData)

		if (props.match.params.check !== "undefined") {
			let cpcDataListWtikkit = [];
            let cpcDataListwOut = [];
			let fullList = [];
			setCpc(true)
			const functions = getFunctions();
			const addEvent = httpsCallable(functions, 'api_event_pro-fetchGuestList');
			const guestData = addEvent({
				eventId: props.match.params.id,
			})
			.then(async (result) => {
				cpcData.map((d, key) => {
					const isCheckedInUser = checkinData.filter((user) => user.key === d.Phone.replace(/-/g, '').replace(/ /g, ""))
                    const hasAdd = {
                        "email": d.PartyEmail,
                        "eventId": props.match.params.id,
                        "guestName": d.PartyFirstName + ' ' + d.PartyLastName,
                        "guestTicket": false,
                        "phone": d.Phone.replace(/-/g, '').replace(/ /g, ""),
                        "quantity": 1,
                        "ticketId": d.Phone.replace(/-/g, '').replace(/ /g, ""),
						"checkedIn": isCheckedInUser.length !== 0 ? true : false,
                        "addons" : {
                            twentyoneonsail: d.twentyoneonsail,
                            ShirtSize: d.ShirtSize,
                            merchpack: d.merchpack
                        }
                    }
                    cpcDataListwOut.push(hasAdd)
                    result.data.hits.map((ticket, keys) => {
                        if (ticket._source.phone === d.Phone.replace(/-/g, '').replace(/ /g, "")) {
							const isCheckedInUser = checkinData.filter((user) => user.key === ticket._source.ticketId)
                            const hasAdd = {
                                "email": ticket._source.email,
                                "eventId": ticket._source.eventId,
                                "guestName": ticket._source.guestName,
                                "guestTicket": ticket._source.guestTicket,
                                "phone": ticket._source.phone,
                                "quantity": ticket._source.quantity,
                                "ticketId": ticket._source.ticketId,
								"checkedIn": isCheckedInUser.length !== 0 ? true : false,
                                "addons" : {
                                    twentyoneonsail: d.twentyoneonsail,
                                    ShirtSize: d.ShirtSize,
                                    merchpack: d.merchpack
                                }
                            }
                            cpcDataListWtikkit.push(hasAdd)
                        }
                    })
                })

				function removeItemAll(arr, value) {
                    var i = 0;
                    while (i < arr.length) {
                      if (arr[i] === value) {
                        arr.splice(i, 1);
                      } else {
                        ++i;
                      }
                    }
                    return arr;
                  }
                cpcDataListWtikkit.map((i, a) => {
                    cpcDataListwOut.map((w, z) => {
                        if (i.phone === w.ticketId) {
                            removeItemAll(cpcDataListwOut, w)
                        }
                    })    
                
                })

				const newData = [...cpcDataListWtikkit, ...cpcDataListwOut];
			
				return newData;
			});
			guestData.then((response) => {
				console.log(response)
				setFullData(response)
				setLoading(false)
			})
		} else {
			const functions = getFunctions();
			const addEvent = httpsCallable(functions, 'api_event_pro-fetchGuestList');
			const guestData = addEvent({
				eventId: props.match.params.id,
			})
			.then(async (result) => {
				let fullList = [];
				result.data.hits.map(async(data, key) => {
					fullList.push(data._source)
				})
				return fullList;
			});
			guestData.then((response) => {
				console.log(response)
				setFullData(response)
				setLoading(false)
			})
		}
		});

	})
}

	const checkInGuest = (ticket) => {
		const functions = getFunctions();
			const addEvent = httpsCallable(functions, 'api_event_pro-checkUserIn');
			const guestData = addEvent({
				ticketId: ticket,
				eventId: props.match.params.id
			})
			.then(async (result) => {
				// getGuestList('With Ticket')
			});
	}

	const checkoutGuest = (ticket) => {
		const functions = getFunctions();
			const addEvent = httpsCallable(functions, 'api_event_pro-checkUserOut');
			const guestData = addEvent({
				ticketId: ticket,
				eventId: props.match.params.id
			})
			.then(async (result) => {
				getGuestList('With Ticket')
			});
	}

	const searchLocations = (searchValue) => {
		let search = (arr, str) => {
		  return arr.filter(x => Object.values(x)
		  .join(' ')
		  .toLowerCase()
		  .includes(str.toLowerCase()))
		}
		
		const found = search(fullData, searchValue)
		setFullData(found)
		if (searchValue === '') {
			getGuestList('With Ticket')
		}
	  }

	useEffect(() => {
		if (props.match.params.id) {
			setLoading(true)
			getGuestList('With Ticket')
		}
	}, [])
	
	const changeDataSet = (name) => {
		if (name === 'With Ticket') {
			getGuestList(name)
		} else {
			getGuestList(name)
		}
	}
	return(
		<>
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Guest list</h4>
                </div>
				{!loading && 
				<div className="card-body">
				<Row>
					<input type="text" style={{ width: '30%', marginRight: '15px'}} id="c-name" className="form-control"  autocomplete="off"
						onChange={(event) => searchLocations(event.target.value)}
						placeholder="Search.."
					/>
					{/* <Tab.Container defaultActiveKey={tabData[0].nameOfTab.toLowerCase()}>
						<Nav as="ul" className="nav-pills mb-1 justify-content-end">
						{tabData.map(
							(data, i) => {
								return (
								<Nav.Item onClick={() => changeDataSet(data.nameOfTab)} as="li" key={i}>
								<Nav.Link eventKey={data.nameOfTab.toLowerCase()}>
									{data.nameOfTab}
								</Nav.Link>
								</Nav.Item>
						)})}
						</Nav>
					</Tab.Container> */}
			  	</Row>
				<div className="table-responsive">
					
					<table className="table filtering-table table-responsive-lg">
						<thead>
						   <tr>
						   		<th>Name</th>
							   	{!cpc && <th>Quantity</th>}
							   {cpc && (
								<>
									<th>Merch pack</th>
									<th>21+ at sail</th>
									<th>Shirt size</th>
							   	</>
							   )}
							   <th>Checkin</th>
							   <th>User checked in</th>
						   </tr>
						</thead> 
						<tbody className="" >
							{fullData.map((data, key) => {
								return (
									<tr>
										<td>{data.guestName}</td>
										{!cpc && <td>{data.quantity}</td>}
										{cpc && (
										<>
										<td>{data.addons.merchpack}</td>
										<td>{data.addons.twentyoneonsail}</td>
										<td>{data.addons.ShirtSize}</td>
										</>
										)}
										<td>{data.checkedIn ? 
										<Button onClick={() => checkoutGuest(data.ticketId)} className="mb-12" style={{fontSize: '11px', color: 'white'}} variant="success btn-rounded">
											<span className="btn-icon-left text-success">
												<i className="fa fa-check color-info" />
											</span>
											Checked-in
										</Button>
										: 
										<Button onClick={() => checkInGuest(data.ticketId)} className="mb-12" style={{fontSize: '11px'}} variant="primary btn-rounded">
											<span className="btn-icon-left text-primary">
												<i className="fa fa-check color-info" />
											</span>
											Check-in
										</Button>
										// <button onClick={() => checkInGuest(data.ticketId)}>Checkin</button>
										}</td>
										<td>{data.checkedIn ? "✅" : "❌"}</td>
									</tr>
								)
							})}
							{/* {page.map((row) => {
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
										})}
						
									</tr>
								)
							})} */}
						</tbody>
					</table>
					{/* <div className="d-flex justify-content-between">
						<span>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{''}
						</span>
						<span className="table-index">
							Go to page : {' '}
							<input type="number" 
								className="ml-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
							/>
						</span>
					</div> */}
					{/* <div className="text-center">	
						<div className="filter-pagination  mt-3">
							<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
							
							<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
							</button>
							<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
							</button>
							<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
						</div>
					</div> */}
				</div>
			</div>
				}
				
			</div>
		</>
	)
	
}
export default Checkin;