import React,{Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import Board, { moveCard } from "@lourenci/react-kanban";
import { board	} from './../Fasto/Kanban/KanbanData';

//import "@lourenci/react-kanban/dist/styles.css";

//Images
import kanban1 from './../../../images/kanban/Untitled-1.jpg';
import kanban2 from './../../../images/kanban/Untitled-2.jpg';
import kanban3 from './../../../images/kanban/Untitled-3.jpg';
import kanban4 from './../../../images/kanban/Untitled-4.jpg';
import kanban5 from './../../../images/kanban/Untitled-5.jpg';
import kanban6 from './../../../images/kanban/Untitled-6.jpg';
import kanban7 from './../../../images/kanban/Untitled-7.jpg';

function ControlledBoard() {
  // You need to control the state yourself.
  const [controlledBoard, setBoard] = useState(board);

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(controlledBoard, source, destination);
    setBoard(updatedBoard);
  }

	return (
		<Board onCardDragEnd={handleCardMove} disableColumnDrag>
			{controlledBoard}
		</Board>
	
	);
}
const Kanban = (props) => {
	const[newProject, setNewProject] = useState(false);
	return(
		<Fragment>
			<div className="project-nav align-items-end">
				<div className="mr-auto  mb-lg-0 mb-3">
					<div className="mb-4">
						<h2 className="title-num text-black font-w700">Project Fasto v2.1 </h2>
						<span className="fs-14">Created by Lidya Chan on June 31, 2021</span>
					</div>
					<div className="d-sm-flex d-block align-items-center">
						<Link to={"#"} className="btn btn-light rounded mr-3 mb-sm-0 mb-2"><i className="fa fa-pencil-square mr-3 scale5" aria-hidden="true"></i>Edit</Link>
						<Link to={"#"} className="btn btn-light rounded mb-sm-0 mb-2"><i className="fa fa-lock mr-3 scale5" aria-hidden="true"></i>Private</Link>
						<ul className="users-lg ml-sm-5 ml-0">
							<li><img src={kanban1} alt="" /></li>
							<li><img src={kanban2} alt="" /></li>
							<li><img src={kanban3} alt="" /></li>
							<li><img src={kanban4} alt="" /></li>
							<li><img src={kanban5} alt="" /></li>
							<li><img src={kanban6} alt="" /></li>
							<li><img src={kanban7} alt="" /></li>
						</ul>
					</div>
				</div>
				<div className="mt-3">
					<Link to={"#"} onClick={ () => setNewProject(true)} className="btn btn-primary  rounded mr-3 mb-sm-0 mb-2 text-white"><i className="fa fa-user mr-3 scale5" aria-hidden="true"></i>New Task</Link>
					{/* <!-- Add Order --> */}
					<Modal className="modal fade" show={newProject} onHide={setNewProject} >
						<div className="" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Add Task</h5>
									<button type="button" className="close" onClick={ () => setNewProject(false)}><span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<form>
										<div className="form-group">
											<label className="text-black font-w500">First Name</label>
											<input type="text" className="form-control" />
										</div>
										<div className="form-group">
											<label className="text-black font-w500">Last Name</label>
											<input type="text" className="form-control" />
										</div>
										<div className="form-group">
											<label className="text-black font-w500">Address</label>
											<input type="text" className="form-control" />
										</div>
										<div className="form-group">
											<button type="button" className="btn btn-primary">SAVE</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</div>
			<div className="kanban-bx">
				<div className="kanbanPreview-bx">
					<div className="card-body">
						<ControlledBoard />
					</div>
				</div>
			</div>
			
		</Fragment>
	)
}
export default Kanban;