/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useState, useContext, useEffect } from "react";
import {Modal} from 'react-bootstrap';
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
//import profile from "../../../images/Untitled-1.jpg";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
     }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
	const [newProject ,setNewProject] = useState(false);
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
	
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }

        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;  
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
    let deshBoard = [
      "",
      "dashboard-dark",
      "projects",
      "contacts",
      "task-list",
      "calendar",
      "messages",
      "task",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
      "todo",
     
    ],
    redux = [
       "redux-form",
	   "redux-wizard",    
       "posts",
    ],
    widget = ["widget-basic"],
    forms = [	
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = [
		"table-bootstrap-basic", 
		"table-datatable-basic",
		"sorting-table",
		"filtering-table",
	],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen", 
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
		{/* <!-- Add Project --> */}
		<Modal className="modal fade" id="addProjectSidebar" show={newProject} onHide={setNewProject}>
			<div className="" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Create Project</h5>
						<button type="button" className="close" onClick={() => setNewProject(false)}><span>&times;</span></button>
					</div>
					<div className="modal-body">
						<form>
							<div className="form-group">
								<label className="text-black font-w500">Project Name</label>
								<input type="text" className="form-control" />
							</div>
							<div className="form-group">
								<label className="text-black font-w500">Deadline</label>
								<input type="date" className="form-control" />
							</div>
							<div className="form-group">
								<label className="text-black font-w500">Client Name</label>
								<input type="text" className="form-control" />
							</div>
							<div className="form-group">
								<button type="button" className="btn btn-primary">CREATE</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Modal>
      <PerfectScrollbar className="deznav-scroll">
     
    <Link to={"/events"} className="add-project-sidebar btn btn-primary" >+ New Event</Link>
        <MM className="metismenu" id="menu">
          <li className={`${path === "" ? "mm-active" : ""}`}>
				<Link className=" ai-icon" to="/" ><i className="flaticon-047-home"></i><span className="nav-text">Dashboard</span></Link>
            {/* <ul>
				
              <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard">Dashboard Light</Link></li>
              <li><Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark">Dashboard Dark</Link></li>
			  <li><Link className={`${path === "projects" ? "mm-active" : ""}`} to="/projects">Project</Link></li>
              <li><Link className={`${path === "contacts" ? "mm-active" : ""}`} to="/contacts">Contacts</Link></li>
			  <li><Link className={`${path === "task-list" ? "mm-active" : ""}`} to="/task-list">Task List</Link> </li>
              <li><Link className={`${path === "calendar" ? "mm-active" : ""}`} to="/calendar">Calendar</Link></li>
              <li><Link className={`${path === "messages" ? "mm-active" : ""}`} to="/messages">Messages</Link></li>
              <li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li>
              <li><Link className={`${path === "chatbox" ? "mm-active" : ""}`} to="/chatbox">ChatBox</Link></li>
            </ul> */}
          </li>
          {/* <li className={`${app.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Apps</span>
            </Link>
            <ul >
              <li><Link className={`${path === "app-profile" ? "mm-active" : ""}`} to="/app-profile">Profile</Link></li>
              <li><Link className={`${path === "post-details" ? "mm-active" : ""}`} to="/post-details">Post Details</Link></li>
              <li className={`${email.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Email</Link>
                <ul  className={`${email.includes(path) ? "mm-show" : ""}`}>
                  <li><Link className={`${ path === "email-compose" ? "mm-active" : ""}`} to="/email-compose">Compose</Link></li>
                  <li><Link className={`${path === "email-inbox" ? "mm-active" : ""}`} to="/email-inbox">Inbox</Link></li>
                  <li><Link className={`${path === "email-read" ? "mm-active" : ""}`} to="/email-read">Read</Link></li>
                </ul>
              </li>
              <li><Link className={`${path === "app-calender" ? "mm-active" : ""}`}to="/app-calender">Calendar</Link></li>
              <li className={`${shop.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Shop</Link>
                <ul  className={`${shop.includes(path) ? "mm-show" : ""}`}>
                  <li><Link className={`${ path === "ecom-product-grid" ? "mm-active" : ""}`} to="/ecom-product-grid">Product Grid</Link></li>
                  <li><Link className={`${ path === "ecom-product-list" ? "mm-active" : ""}`} to="/ecom-product-list">Product List</Link></li>
                  <li><Link className={`${ path === "ecom-product-detail" ? "mm-active" : "" }`} to="/ecom-product-detail">Product Details</Link></li>
                  <li><Link className={`${ path === "ecom-product-order" ? "mm-active" : "" }`} to="/ecom-product-order">Order</Link></li>
                  <li><Link className={`${ path === "ecom-checkout" ? "mm-active" : ""}`} to="/ecom-checkout">Checkout</Link></li>
                  <li><Link className={`${ path === "ecom-invoice" ? "mm-active" : "" }`} to="/ecom-invoice">Invoice</Link></li>
                  <li><Link className={`${ path === "ecom-customers" ? "mm-active" : "" }`} to="/ecom-customers">Customers</Link></li>
                </ul>
              </li>
            </ul>
          </li>
          <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Charts</span>
            </Link>
				<ul>
				  <li><Link className={`${path === "chart-rechart" ? "mm-active" : ""}`} to="/chart-rechart">RechartJs</Link></li>
				  <li><Link className={`${path === "chart-chartjs" ? "mm-active" : ""}`} to="/chart-chartjs">Chartjs</Link></li>
				  <li><Link className={`${path === "chart-chartist" ? "mm-active" : ""}`} to="/chart-chartist">Chartist</Link></li>
				  <li><Link className={`${path === "chart-sparkline" ? "mm-active" : ""}`} to="/chart-sparkline">Sparkline</Link></li>
				  <li><Link className={`${path === "chart-apexchart" ? "mm-active" : ""}`} to="/chart-apexchart">Apexchart</Link></li>
				</ul>
          </li>
          <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-086-star"></i>
              <span className="nav-text">Bootstrap</span>
            </Link>
            <ul >
              <li><Link className={`${path === "ui-accordion" ? "mm-active" : ""}`} to="/ui-accordion">Accordion</Link></li>
              <li><Link className={`${path === "ui-alert" ? "mm-active" : ""}`} to="/ui-alert">Alert</Link></li>
              <li><Link className={`${path === "ui-badge" ? "mm-active" : ""}`} to="/ui-badge">Badge</Link></li>
              <li><Link className={`${path === "ui-button" ? "mm-active" : ""}`} to="/ui-button">Button</Link></li>
              <li><Link className={`${path === "ui-modal" ? "mm-active" : ""}`}to="/ui-modal">Modal</Link></li>
              <li><Link className={`${path === "ui-button-group" ? "mm-active" : ""}`} to="/ui-button-group">Button Group</Link></li>
              <li><Link className={`${path === "ui-list-group" ? "mm-active" : ""}`} to="/ui-list-group">List Group</Link></li>
              <li><Link className={`${path === "ui-media-object" ? "mm-active" : ""}`} to="/ui-media-object">Media Object</Link></li>
              <li><Link className={`${path === "ui-card" ? "mm-active" : ""}`} to="/ui-card">Cards</Link></li>
              <li><Link className={`${path === "ui-carousel" ? "mm-active" : ""}`} to="/ui-carousel">Carousel</Link></li>
              <li><Link className={`${path === "ui-dropdown" ? "mm-active" : ""}`} to="/ui-dropdown">Dropdown</Link></li>
              <li><Link className={`${path === "ui-popover" ? "mm-active" : ""}`} to="/ui-popover">Popover</Link></li>
              <li><Link className={`${path === "ui-progressbar" ? "mm-active" : ""}`} to="/ui-progressbar">Progressbar</Link></li>
              <li><Link className={`${path === "ui-tab" ? "mm-active" : ""}`} to="/ui-tab">Tab</Link></li>
              <li><Link className={`${path === "ui-typography" ? "mm-active" : ""}`} to="/ui-typography">Typography</Link></li>
              <li><Link className={`${path === "ui-pagination" ? "mm-active" : ""}`} to="/ui-pagination" > Pagination</Link></li>
              <li><Link className={`${path === "ui-grid" ? "mm-active" : ""}`} to="/ui-grid">Grid</Link></li>
            </ul>
          </li>
          <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
				<Link className="has-arrow ai-icon" to="#" >
					<i className="flaticon-045-heart"></i><span className="nav-text">Plugins</span>
				</Link>
            <ul >
              <li><Link className={`${path === "uc-select2" ? "mm-active" : ""}`} to="/uc-select2">Select 2</Link></li>
              <li><Link className={`${path === "uc-nestable" ? "mm-active" : ""}`} to="/uc-nestable">Nestable</Link></li>
              <li><Link className={`${path === "uc-noui-slider" ? "mm-active" : ""}`} to="/uc-noui-slider">Noui Slider</Link></li>
              <li><Link className={`${path === "uc-sweetalert" ? "mm-active" : ""}`} to="/uc-sweetalert">Sweet Alert</Link></li>
              <li><Link className={`${path === "uc-toastr" ? "mm-active" : ""}`} to="/uc-toastr">Toastr</Link></li>
              <li><Link className={`${path === "map-jqvmap" ? "mm-active" : ""}`} to="/map-jqvmap">Jqv Map</Link></li>
              <li><Link className={`${path === "uc-lightgallery" ? "mm-active" : ""}`} to="/uc-lightgallery">Light Gallery</Link></li>
				  <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/todo">Todo</Link></li> 
              
            </ul>
          </li>
            <li className={`${redux.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow ai-icon" to="#" >
                        <i className="flaticon-068-plus"></i><span className="nav-text">Redux</span>
                    </Link>
                <ul>
                  <li><Link className={`${path === "posts" ? "mm-active" : ""}`} to="/posts">Posts</Link></li>
                  <li><Link className={`${path === "redux-form" ? "mm-active" : ""}`} to="/form-redux">Redux Form</Link></li>
                  <li><Link className={`${path === "redux-wizard" ? "mm-active" : ""}`} to="/form-redux-wizard">Redux Wizard</Link></li>
                </ul>
            </li>
          <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
            <Link to="widget-basic" className="ai-icon" >
              <i className="flaticon-013-checkmark"></i>
              <span className="nav-text">Widget</span>
            </Link>
          </li> */}
          <li className={`${path === "clients" ? "mm-active" : ""}`}>
            <Link to="/clients" className="ai-icon" >
              <i className="flaticon-381-user"></i>
              <span className="nav-text">Organizations</span>
            </Link>
          </li>
          <li className={`${path === "team" ? "mm-active" : ""}`}>
            <Link to="/team" className="ai-icon" >
              <i className="flaticon-381-user"></i>
              <span className="nav-text">My Team</span>
            </Link>
          </li>
          <li className={`${path === "events" ? "mm-active" : ""}`}>
            <Link to="/events" className="ai-icon" >
              <i className="fa fa-calendar"></i>
              <span className="nav-text">Events</span>
            </Link>
          </li>
          <li className={`${path === "analytics" ? "mm-active" : ""}`}>
            <Link to="/analytics" className="ai-icon" >
              <i className="fa fa-list"></i>
              <span className="nav-text">Analytics</span>
            </Link>
          </li>
          <li className={`${path === "settings" ? "mm-active" : ""}`}>
            <Link to="/settings" className="ai-icon" >
              <i className="fa fa-gear"></i>
              <span className="nav-text">Settings</span>
            </Link>
          </li>
          {/* <li className={`${forms.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-072-printer"></i>
              <span className="nav-text forms">Forms</span>
            </Link>
            <ul>
				<li><Link className={`${path === "form-element" ? "mm-active" : ""}`} to="/form-element">Form Elements</Link></li>
				<li><Link className={`${path === "form-wizard" ? "mm-active" : ""}`} to="/form-wizard">Wizard</Link></li>
			    <li><Link className={`${ path === "form-editor-summernote" ? "mm-active" : "" }`}to="/form-editor-summernote">Summernote</Link></li>
			    <li><Link className={`${path === "form-pickers" ? "mm-active" : ""}`} to="/form-pickers">Pickers</Link></li>
			    <li><Link className={`${ path === "form-validation-jquery" ? "mm-active" : "" }`} to="/form-validation-jquery">Jquery Validate</Link>
			    </li>
            </ul>
          </li>
          <li className={`${table.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-017-clipboard"></i>
              <span className="nav-text">Table</span>
            </Link>
            <ul>
				<li><Link className={`${ path === "table-bootstrap-basic" ? "mm-active" : "" }`} to="/table-bootstrap-basic">Bootstrap</Link></li>
				<li><Link className={`${ path === "table-datatable-basic" ? "mm-active" : "" }`} to="/table-datatable-basic">Datatable</Link></li>
				<li><Link className={`${ path === "sorting-table" ? "mm-active" : "" }`} to="/sorting-table">Sorting Table</Link></li>
				<li><Link className={`${ path === "filtering-table" ? "mm-active" : "" }`} to="/filtering-table">Filtering Table</Link></li>
            </ul>
          </li>
          <li className={`${pages.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-022-copy"></i>
              <span className="nav-text">Pages</span>
            </Link>
            <ul>
				<li className={`${error.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow" to="#" >Error</Link>
					<ul>
						<li><Link className={`${ path === "page-error-400" ? "mm-active" : ""}`} to="/page-error-400">Error 400</Link></li>
						<li><Link className={`${ path === "page-error-403" ? "mm-active" : ""}`} to="/page-error-403">Error 403</Link></li>
						<li><Link className={`${ path === "page-error-404" ? "mm-active" : ""}`} to="/page-error-404">Error 404</Link></li>
						<li><Link className={`${ path === "page-error-500" ? "mm-active" : ""}`} to="/page-error-500">Error 500</Link></li>
						<li><Link className={`${ path === "page-error-503" ? "mm-active" : ""}`} to="/page-error-503">Error 503</Link></li>
					</ul>
				</li>
				<li> <Link className={`${ path === "page-lock-screen" ? "mm-active" : ""}`} to="/page-lock-screen">Lock Screen</Link></li>
            </ul>
          </li> */}
        </MM>
  
		<div className="copyright">
			<p><strong>Tikkit</strong> © 2022 All Rights Reserved</p>
			<p className="fs-12">Made with <span className="heart"></span> by Tikkit</p>
		</div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
