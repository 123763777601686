	import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
const img = '../../../images/svg/Tikkit Pro Web Square.svg'

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
         {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
				{/* <img src="Tikkit Pro Web Square.png" width="85" height="27" />
				<img src="Tikkit Pro Web Square.png" width="105" height="27" /> */}
          </Fragment>
        ) : (
          <Fragment>
				<img src="Tikkit Pro Web Square.png" width="55" height="55" />
				<img src="Tikkit Pro Logo.png" width="105" height="35" />
				{/* <svg className="brand-title" width="85" height="27" viewBox="0 0 85 27" fill="none" xmlns="http://www.w3.org/2000/svg">
				<img src="Tikkit Pro Web Square.png" width="85" height="27" />
				</svg> */}
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
