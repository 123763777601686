import React,{Fragment, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Modal, Button} from 'react-bootstrap';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import swal from "sweetalert";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getDatabase, ref, onValue } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import {addTodo, deleteTodo} from '../../../store/actions/index';
import {useSelector, useDispatch} from 'react-redux';
//import ImageBlog from '../Fasto/Contacts/ImageBlog';
import ContactsData from '../Fasto/Contacts/ContactsData';
import { onSnapshot, getFirestore, collection, query, updateDoc, addDoc, deleteDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
//Images
import user from './../../../images/contacts/user.jpg';
import { getStorage } from '../../../services/AuthService';


const Contacts = () =>{
	const [me, setMe] = useState([])
	const [pending, setPending] = useState([])
	const [selectedOption, setSelectedOption] = useState(null);
	const [allCompanies, setAllCompanies] = useState([])
	const [dropdownCompanies, setDropDownCompanies] = useState([])
	const db = getDatabase();
	let companies = [];
	let dropdown = [];

	function getUniqueListBy(arr, key) {
		return [...new Map(arr.map(item => [item[key], item])).values()]
	}
	const getCompanies = (val) => {
		setDropDownCompanies([])
		const starCountRef = ref(db, 'companies');
		onValue(starCountRef, (snapshot) => {	
		snapshot.forEach(data => {
			const dataVal = data.val()
			if (dataVal.clientOf === val.companyId) {
				dropdown.push({
					value: data.key,
					label: dataVal.company,
				})
			}
			
		})
		const arr2 = getUniqueListBy(dropdown, 'value')
		setDropDownCompanies(arr2)
		});
	}

	const getClients = (authedUser) => {
		setAllCompanies([])
		let currentUserComp;
		const userRef = ref(db, 'users');
		onValue(userRef, (snapshot) => {	
		snapshot.forEach(data => {
			const dataVal = data.val()
			if (dataVal.phone === authedUser) {
				setMe(dataVal)
				getCompanies(dataVal)
				currentUserComp = dataVal.companyId
			}
			if (dataVal.proUser === true) {
				companies.push({
					id: data.key,
					full_name: dataVal.full_name,
					clientOf: dataVal.clientOwnerId,
					clientName: dataVal.clientOwnerName,
					company: dataVal.company,
					companyId: dataVal.companyId
				})
				
			}
		})
		const arr1 = getUniqueListBy(companies, 'id')
		const onlyCompUsers = arr1.filter((i) => i.clientOf === currentUserComp && i.companyId !== currentUserComp)

		setAllCompanies(onlyCompUsers)
		});
	}

	  useEffect(() => {
		const authedUser = getStorage()
		getClients(authedUser)
	  }, [])
	//tab function 
	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	
	// This is Model function
	const [addContact, setAddContact] = useState(false);
	const [addCompany, setAddCompany] = useState(false)
	const [addNewCompany, setAddNewCompany] = useState(false)
	
	//Remove and delete List from Blog 'function'
	const [data, setData] = useState([]);

	//Add and delete data from list
	const dispatch = useDispatch();	
    let list = useSelector((state) => state.todoReducers.list);
	const [list_name, setInputData] = useState('');
	const [list_occupation, setInputData1] = useState('');
	const [email, setEmail] = useState('')
	const [lastName, setLastName] = useState('')
	const [companyName, setCompanyName] = useState('')

	const submitNewClient = () => {
		const user = {
			// email: email,
			phone: list_occupation,
			full_name: list_name + ' ' +lastName,
			// clientOwner: 'tikkit',
			// company: '',
		}

		const functions = getFunctions();
		const addUser = httpsCallable(functions, 'api_user-createDBProUser');
		const updateUser = httpsCallable(functions, 'api_user-updateProUser');

		addUser({ user })
		.then((result) => {
			// Read result of the Cloud Function.
			/** @type {any} */
			// const data = result.data;
			const userUpdate = {
				email: email,
				first: list_name,
				last: lastName,
				full_name: list_name + ' ' +lastName,
				clientOwnerId: me.companyId,
				clientOwnerName: me.company,
				company: selectedOption.label,
				companyId: selectedOption.value,
				uid: result.data.data
			}
			updateUser({ user: userUpdate })
			.then((result) => {
			});
		});
	
	
	setInputData('');
	setInputData1('');
	setAddContact(false);
	swal('Good job!', 'Successfully Added', "success");
	}

	//Error message 
	function onAddData(e){
		e.preventDefault();
		var error = false;
		var errorMsg = '';
		if(list_name === ''){
			error = true;
			errorMsg = 'Please fill out first name.';
		}else if(list_occupation === ''){
			error = true;
			errorMsg = 'Please fill out title.';
		}else if(email === ''){
			error = true;
			errorMsg = 'Please fill out email.';
		} else if (lastName === '') {
			error = true;
			errorMsg = 'Please fill out last name.';
		}
	  
		if(!error){
			if (addNewCompany) {
				onAddCompany()
			} else {
				submitNewClient()
			}
		}else{
			swal('Oops', errorMsg, "error");
		}
    }

	function onAddCompany(e){
		e.preventDefault();
		var error = false;
		var errorMsg = '';
		if(companyName === ''){
			error = true;
			errorMsg = 'Please fill out company name.';
		}
	  
		if(!error){

				const user = {
					company: companyName,
					clientOf: me.companyId
				}

				const functions = getFunctions();
				const addMessage = httpsCallable(functions, 'api_user-createCompanyPro');
				addMessage({ user })
				.then((result) => {
					// Read result of the Cloud Function.
					/** @type {any} */
					// const data = result.data;
				});
            setCompanyName('');
			setAddCompany(false);
			swal('Good job!', 'Successfully Added', "success");
			return 
		}else{
			swal('Oops', errorMsg, "error");
		}
    }

	const handleRemove = () => {

	}	
	return(
		<Fragment>
			<div className="project-nav">
				<div className="card-action card-tabs  mr-auto">
					<ul className="nav nav-tabs style-2">
						<li className="nav-item">
							<Link to ={"#"} className= {classnames({ active : activeTab === '1'}) + ' nav-link'} onClick={() => { toggle('1'); }}>All Clients
								<span className="ml-1 badge badge-primary shadow-primary">{allCompanies.length}</span>
							</Link>
						</li>
						{/* <li className="nav-item">
							<Link to ={"#"} className= {classnames({ active : activeTab === '2'}) + ' nav-link'} onClick={() => { toggle('2'); }}>Pending Invitation
								<span className="ml-1 badge shadow-warning  badge-warning">{pending.length}</span>
							</Link>
						</li>	 */}
					</ul>
				</div>
				<div className="d-flex align-items-center">
					<Link to={"#"} id="btn-add-contact" onClick={()=> setAddContact(true)} className="btn btn-primary rounded text-white">+ New Client</Link>
					<div className="switch">
					</div>
				</div>
				<div className="d-flex align-items-center ml-2">
					<Link to={"#"} id="btn-add-contact" onClick={()=> setAddCompany(true)} className="btn btn-primary rounded text-white">+ New Org</Link>
					<div className="switch">
					</div>
				</div>
				{/* <!-- Modal --> */}
				<Modal className="modal fade" id="addContactModal"  show={addContact} onHide={setAddContact}>
					<div className="" role="document">
						<div className="">
							<form id="addContactModalTitle" onSubmit={onAddData}>
								<div className="modal-header">
									<h4 className="modal-title fs-20">Add Client</h4>
									<button type="button" className="close" onClick={()=> setAddContact(false)} data-dismiss="modal"><span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											{/* <div className="image-placeholder">	
												<div className="avatar-edit">
													<input type="file" onChange={fileHandler} id="imageUpload"
														onClick={(event) => setFile(event.target.value)}
													/> 					
													<label htmlFor="imageUpload" name=''  ></label>
												</div>
												<div className="avatar-preview">
													<div id="imagePreview">
														<img src={file? URL.createObjectURL(file) : user} alt={file? file.name : null}/>
													</div>
												</div> */}
												{/*<ImageBlog></ImageBlog>*/}
											{/* </div> */}
											<div className="form-group">
												<label className="text-black font-w500">First name</label>
												<div className="contact-name">
													<input type="text" id="c-name" className="form-control"  autocomplete="off"
														onChange={(event) => setInputData(event.target.value)}
														placeholder="Full name"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group">
												<label className="text-black font-w500">Last name</label>
												<div className="contact-name">
													<input type="text" id="c-name" className="form-control"  autocomplete="off"
														onChange={(event) => setLastName(event.target.value)}
														placeholder="Full name"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group">
												<label className="text-black font-w500">Users email</label>
												<div className="contact-name">
													<input type="text" id="c-name" className="form-control"  autocomplete="off"
														onChange={(event) => setEmail(event.target.value)}
														placeholder="Users email"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group">
												<label className="text-black font-w500">Users phone #</label>
												<div className="contact-occupation">
													<input type="text"  id="c-occupation" autocomplete="off"
														onChange={(event) => setInputData1(event.target.value)}
														className="form-control" placeholder="Users Phone #" 
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="text-black font-w500">Clients org</label>
												{!addNewCompany && 
													<Button onClick={() => setAddCompany(true)} className="ml-3 mb-12" style={{fontSize: '11px'}} variant="primary btn-rounded">
														<span className="btn-icon-left text-primary">
															<i className="fa fa-plus color-info" />
														</span>
														Add Org
													</Button>
												}
												{addNewCompany &&
													<Button onClick={() => setAddCompany(false)} className="ml-3 mb-12" style={{fontSize: '11px'}} variant="primary btn-rounded">
														Choose Org
													</Button>
												}
												{addNewCompany && 
													<div style={{marginTop: "10px"}} className="contact-occupation">
														<input type="text"  id="c-occupation" autocomplete="off"
															onChange={(event) => setCompanyName(event.target.value)}
															className="form-control" placeholder="New company name.." 
														/>
													</div>
												}
												{!addNewCompany && 
													<div style={{marginTop: "10px"}} className="contact-occupation">
													<Select
														defaultValue={selectedOption}
														onChange={setSelectedOption}
														options={dropdownCompanies}
														style={{
														lineHeight: "40px",
														color: "#7e7e7e",
														paddingLeft: " 15px",
														}}
													/>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									{/*<button type="submit" id="btn-edit" className="float-left btn btn-primary">Save</button>*/}
									<button type="button"  className="btn btn-info" onClick={()=> setAddContact(false)}> <i className="flaticon-delete-1"></i> Cancel</button>
									<button id="btn-add" className="btn btn-primary">Add</button> 
								</div>
							</form>
						</div>
					</div>
				</Modal>

				<Modal className="modal fade" id="addContacModal"  show={addCompany} onHide={setAddCompany}>
					<div className="" role="document">
						<div className="">
							<form id="addContacModalTitle" onSubmit={onAddCompany}>
								<div className="modal-header">
									<h4 className="modal-title fs-20">Add Company</h4>
									<button type="button" className="close" onClick={()=> setAddContact(false)} data-dismiss="modal"><span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											<div className="form-group">
												<label className="text-black font-w500">Company name</label>
												<div className="contact-name">
													<input type="text" id="c-name" className="form-control"  autocomplete="off"
														onChange={(event) => setCompanyName(event.target.value)}
														placeholder="Company name"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button"  className="btn btn-info" onClick={()=> setAddCompany(false)}> <i className="flaticon-delete-1"></i> Cancel</button>
									<button id="btn-add" className="btn btn-primary">Add</button> 
								</div>
							</form>
						</div>
					</div>
				</Modal>								
			</div>	
			<div className="tab-content">
				<div className="tab-pane fade show active" id="navpills-1" >
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<PerfectScrollbar className="row dz-scroll  loadmore-content searchable-items list" id="allContactListContent">
								<div className="items items-header-section">
								</div>
								
								{allCompanies.map((item)=>(	
									<div className="col-xl-3 col-xxl-4 col-lg-4 col-md-6 col-sm-6 items" key={item[1]}>
										<div className="card contact-bx item-content">
											{/* <div className="card-header border-0">
													<div className="action-dropdown">
													<Dropdown className="">
														<Dropdown.Toggle variant="" as="div" className="i-false">
															<Link to={"#"} data-toggle="dropdown" aria-expanded="false">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																</svg>
															</Link>
														</Dropdown.Toggle>	
														<Dropdown.Menu className="dropdown-menu-right" alignRight={true}>
															<Dropdown.Item className="delete text-danger"  onClick={() => handleRemove(item.id)}>Remove user</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
												
											</div> */}
											<div className="card-body user-profile">
												{/* <div className="image-bx">
													<img src={item.image}  alt="" className="rounded-circle" />
													<span className="active"></span>
												</div> */}
												<div className="media-body user-meta-info">
													<h6 className="fs-20 font-w500 my-1"><Link to={"#"} className="text-black user-name" data-name="Engeline O’conner">{item.full_name}</Link></h6>
													<p className="fs-14 mb-3 user-work" data-occupation="UI Designer">{item.company}</p>
													<p className="fs-14 mb-3 user-work" data-occupation="UI Designer">Client of {item.clientName}</p>
													<ul>
														{/* <li><Link to={"#"}><i className="fa fa-phone" aria-hidden="true"></i></Link></li> */}
														{/* <li><Link to={"./messages"}><i className="las la-sms"></i></Link></li> */}
														{/* <li><Link to={"#"}><i className="fa fa-video-camera" aria-hidden="true"></i></Link></li> */}
													</ul>
												</div>
											</div>
										</div>
									</div>
								))}
								
								{ list.map((elem) =>{return(
									<div className="col-xl-3 col-xxl-4 col-lg-4 col-md-6 col-sm-6 items" key={elem.id}>
										<div className="card contact-bx item-content">
											<div className="card-header border-0">
												<div className="action-dropdown">
													<Dropdown className="">
														<Dropdown.Toggle variant="" as="div" className="i-false">
															<Link to={"#"} data-toggle="dropdown" aria-expanded="false">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																</svg>
															</Link>
														</Dropdown.Toggle>	
														<Dropdown.Menu className="dropdown-menu-right" alignRight={true}>
															<Dropdown.Item className="delete text-danger" onClick={() => dispatch(deleteTodo(elem.id))}>Delete</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</div>
											<div className="card-body user-profile">
												<div className="image-bx">
													<img src={(elem.data.file)? URL.createObjectURL(elem.data.file) : user}  alt="" className="rounded-circle" />
													<span className="active"></span>
												</div>
												<div className="media-body user-meta-info">
													<h6 className="fs-20 font-w500 my-1"><Link to={"./app-profile"} className="text-black user-name" data-name="Alan Green">{elem.data.list_name}</Link></h6>
													<p className="fs-14 mb-3 user-work" data-occupation="UI Designer">{elem.data.list_occupation}</p>
													<ul>
														{/* <li><Link to={"#"} ><i className="fa fa-phone" aria-hidden="true"></i></Link></li> */}
														<li><Link to={"./messages"}><i className="las la-sms"></i></Link></li>
														{/* <li><Link to={"#"}><i className="fa fa-video-camera" aria-hidden="true"></i></Link></li> */}
													</ul>
												</div>
											</div>
										</div>
									</div>
								)})}
							</PerfectScrollbar>
							{/* <div className="row">
								<div className="col-xl-12 d-flex justify-content-center">
									<Link to={"#"} className="btn btn-outline-primary dz-load-more"  onClick={() => onClick()}>
										Load More<span>&#187;</span>{" "}
										{refresh && <i className="fa fa-refresh"></i>}
									</Link>
								</div>
							</div> */}
						</TabPane>	
						<TabPane tabId="2">
						<PerfectScrollbar className="row dz-scroll  loadmore-content searchable-items list" id="allContactListContent">
								<div className="items items-header-section">
								</div>
								
								{pending.map((item)=>(	
									<div className="col-xl-3 col-xxl-4 col-lg-4 col-md-6 col-sm-6 items" key={item[1]}>
										<div className="card contact-bx item-content">
											<div className="card-header border-0">
													<div className="action-dropdown">
													<Dropdown className="">
														<Dropdown.Toggle variant="" as="div" className="i-false">
															<Link to={"#"} data-toggle="dropdown" aria-expanded="false">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																</svg>
															</Link>
														</Dropdown.Toggle>	
														<Dropdown.Menu className="dropdown-menu-right" alignRight={true}>
															<Dropdown.Item className="delete text-danger"  onClick={() => handleRemove(item[1])}>Remove user</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
												
											</div>
											<div className="card-body user-profile">
												{/* <div className="image-bx">
													<img src={item.image}  alt="" className="rounded-circle" />
													<span className="active"></span>
												</div> */}
												<div className="media-body user-meta-info">
													<h6 className="fs-20 font-w500 my-1"><Link to={"./app-profile"} className="text-black user-name" data-name="Engeline O’conner">{item[0].name}</Link></h6>
													<p className="fs-14 mb-3 user-work" data-occupation="UI Designer">{item[0].title}</p>
													{/* <ul>
														<li><Link to={"#"}><i className="fa fa-phone" aria-hidden="true"></i></Link></li>
														<li><Link to={"./messages"}><i className="las la-sms"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-video-camera" aria-hidden="true"></i></Link></li>
													</ul> */}
												</div>
											</div>
										</div>
									</div>
								))}
								
							
							</PerfectScrollbar>
						</TabPane>
					</TabContent> 	
				</div>
			</div>		
		</Fragment>
	)
}
export default Contacts;