import React,{ useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import {
	completeLogin,
    loadingToggleAction,
    loginAction,
} from '../../store/actions/AuthActions';
// image
import logo2 from "../../images/logo-full-white.png";
import login from "../../images/login-bg.jpg";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { addDoc, getFirestore, collection } from '@firebase/firestore';
function Login(props) {
	const history = useHistory()
	const [email, setEmail] = useState('demo@demo.com');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('123456');
	const [sent, setSent] = useState(false)
	const [code, setCode] = useState(null)
	const [enteredCode, setEnteredCode] = useState(null)
	const db = getFirestore()
	const auth = getAuth();
    const dispatch = useDispatch();
	const appVerifier = window.recaptchaVerifier;
   //setEmail('');
    //setPassword('123456');
    function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Phone number is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return;
		}   
	
		
		dispatch(loadingToggleAction(true));
			dispatch(loginAction(email, appVerifier, props.history, setSent, setCode));

	

    }

	function onSubmitCode(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (enteredCode === null) {
            errorObj.email = 'Code is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return;
		}   
	
		code.confirm(enteredCode).then((result) => {
			// User signed in successfully.
			const user = result.user;
			console.log(props.history)
			console.log(result)
			dispatch(loadingToggleAction(true));
			dispatch(completeLogin(result, props.history));
			// ...
		  }).catch((error) => {
			  console.log(error)
			// User couldn't sign in (bad verification code?)
			// ...
		  });


	

    }

	useEffect(() => {
		console.log(appVerifier)
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response) => {
				// reCAPTCHA solved, allow signInWithPhoneNumber.
				console.log("response",response)
			   
			},
			'expired-callback': () => {
				console.log('here')
				// Response expired. Ask user to solve reCAPTCHA again.
				// ...
			  }
			}, auth);
	}, [])

  return (
		<div className="login-wrapper">
			<div className="login-aside-left" style={{backgroundImage:"url("+ login +")"}}>
				<Link to="/" className="login-logo">
					{/* <img src={logo2} alt="" /> */}
				  </Link>
				<div className="login-description">
					{/* <h2 className="text-white mb-4">Stay connected virtually</h2> */}
					{/* <p className="fs-12">Use our video tool for anything internally or chat to stay connected!</p> */}
					{/* <ul className="social-icons mt-4">
						<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
						<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
						<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
					</ul> */}
					<div className="mt-5">
						<Link to={"#"} className="text-white mr-4">Privacy Policy</Link>
						<Link to={"#"} className="text-white mr-4">Contact</Link>
						<Link to={"#"} className="text-white">© 2021 Tikkit.io</Link>
					</div>
				</div>
			</div>
			<div className="login-aside-right">
				<div className="row m-0 justify-content-center h-100 align-items-center">
				  <div className="col-xl-6 col-xxl-8">
					<div className="authincation-content">
					  <div className="row no-gutters">
						<div className="col-xl-12">
						  <div className="auth-form">
							<div className=" mb-3">
							  <h2 className="text-primary">Welcome to Tikkit Pro</h2>
							</div>
							<h4 className=" mb-4 ">Sign in by entering your phone number below</h4>
                            {props.errorMessage && (
                                <div className='text-danger'>
                                    Please enter your phone number
                                </div>
                            )}
                            {props.successMessage && (
                                <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                    {props.successMessage}
                                </div>
                            )}
							{!sent && <form onSubmit={onLogin}>
                                <div className="form-group">
									<label className="mb-2 ">
									  <strong>Phone number</strong>
									</label>
									<input type="text" className="form-control"
									   onChange={(e) => setEmail(e.target.value)}
                                       //defaultValue="abcd@gmail.com"
									/>
								  {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
								</div>
							  <div className="text-center">
								<button
								  type="submit"
								  id="sign-in-button"
								  className="btn btn-primary btn-block"
								>
								  Sign In
								</button>
							  </div>
							</form>}
							{sent && <form onSubmit={onSubmitCode}>
                                <div className="form-group">
									<label className="mb-2 ">
									  <strong>Code</strong>
									</label>
									<input type="text" className="form-control"
									   onChange={(e) => setEnteredCode(e.target.value)}
                                       //defaultValue="abcd@gmail.com"
									/>
								  {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
								</div>
							  <div className="text-center">
								<button
								  type="submit"
								  id="sign-in-button"
								  className="btn btn-primary btn-block"
								>
								 Submit code
								</button>
							  </div>
							</form> }
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			</div>
		</div>
  );
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);
