import React,{Fragment, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Modal, Button} from 'react-bootstrap';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import swal from "sweetalert";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getDatabase, ref, onValue, equalTo, orderByChild, query } from "firebase/database";
import Switch from "react-switch";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, getUserDetailsStorage } from '../../../services/AuthService';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const Events = () => {
	const [me, setMe] = useState([])
	const [meId, setMeId] = useState(null)
	const [allCompanies, setAllCompanies] = useState([])
	const [events, setEvents] = useState([])
	const db = getDatabase();
	const userDetails = JSON.parse(getUserDetailsStorage())
	console.log(userDetails)
	let companies = [];
	let forAllEvents = [];
	

	function getUniqueListBy(arr, key) {
		return [...new Map(arr.map(item => [item[key], item])).values()]
	}

	const getEvents = (val) => {
		const starCountRef = query(ref(db, 'events'), orderByChild('isPro'), equalTo(true))
		onValue(starCountRef, (snapshot) => {	
		snapshot.forEach(data => {
			const dataVal = [data.val(),data.key]
			if (dataVal[0].launchingCompany === val) {
				forAllEvents.push(dataVal)
			}
 		})
		
		const arr2 = getUniqueListBy(forAllEvents, '1')
		setEvents(arr2)
		});
	}

	const getClients = (authedUser) => {
		setAllCompanies([])
		let currentUserComp;
		const userRef = ref(db, 'users');
		onValue(userRef, (snapshot) => {	
		snapshot.forEach(data => {
			const dataVal = data.val()
			if (dataVal.phone === authedUser) {

				setMe(dataVal)
				setMeId(data.key)
				getEvents(dataVal.companyId)
				currentUserComp = dataVal.company
			}
			if (dataVal.proUser === true) {
				companies.push({
					id: data.key,
					full_name: dataVal.full_name,
					clientOf: dataVal.clientOwner,
					company: dataVal.company
				})
				
			}
		})
		const arr1 = getUniqueListBy(companies, 'id')
		setAllCompanies(arr1)
		});
	}

	  useEffect(() => {
		const authedUser = getStorage()
		getClients(authedUser)
	  }, [])
	//tab function 
	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	
	// This is Model function
	const [addContact, setAddContact] = useState(false);


	const [date, setDate] = useState(new Date())
	const [info, setInfo] = useState('')
	const [price, setPrice] = useState(0)
	const [privateParty, setPrivateParty] = useState(false)
	const [pin, setPin] = useState('')
	const [title, setTitle] = useState('')
	const [locationOfEvent, setLocationOfEvent] = useState(null)
	//Error message 
	function onAddData(e){
		e.preventDefault();
		if (locationOfEvent !== null) {
			geocodeByAddress(locationOfEvent.label)
		.then(results => getLatLng(results[0]))
		.then(({ lat, lng }) => {
			var error = false;
		var errorMsg = '';
		if(title === ''){
			error = true;
			errorMsg = 'Please fill out title.';
		}
	  
		if(!error){
			const locationInfo = {
				"displayName": locationOfEvent.label,
				 "geometry": {"lat": lat, "lng": lng},
				  "id": locationOfEvent.value.place_id
			}
				const event = {
					background: 'baboo',
					bouncer: 'quack',
					date: date,
					info: info,
					location: locationOfEvent.label,
					title: title,
					titleColor: "hsl(112, 100%, 58%)",
					private: privateParty,
					pin: pin,
					isPro: true,
					launchingCompany: me.companyId,
					launchingCompanyName: me.company,
					// clientOf: ,
					uid: meId
				}
				const functions = getFunctions();
				const addEvent = httpsCallable(functions, 'api_event_pro-launchEvent');

				addEvent({
					eventInfo: event,
					price: price,
					locationInfo: locationInfo,
				})
				.then((result) => {
				
				});
			
			
            setDate('');
            setLocationOfEvent(null);
			setTitle('')
			setInfo('')
			setPrivateParty(false)
			setPin('')
			setAddContact(false);
			swal('Good job!', 'Successfully Launched your event', "success");
		}else{
			swal('Oops', errorMsg, "error");
		}
		});
		} else {
			swal('Error', "Please enter a location", "error");
		}
    }

	const handleRemove = () => {

	}

	
	return(
		<Fragment>
			<div className="project-nav">
				<div className="card-action card-tabs  mr-auto">
					<ul className="nav nav-tabs style-2">
						<li className="nav-item">
							<Link to ={"#"} className= {classnames({ active : activeTab === '1'}) + ' nav-link'} onClick={() => { toggle('1'); }}>All Events
								<span className="ml-1 badge badge-primary shadow-primary">{events.length}</span>
							</Link>
						</li>
						{/* <li className="nav-item">
							<Link to ={"#"} className= {classnames({ active : activeTab === '2'}) + ' nav-link'} onClick={() => { toggle('2'); }}>Pending Invitation
								<span className="ml-1 badge shadow-warning  badge-warning">{pending.length}</span>
							</Link>
						</li>	 */}
					</ul>
				</div>
				<div className="d-flex align-items-center">
					<Link to={"#"} id="btn-add-contact" onClick={()=> setAddContact(true)} className="btn btn-primary rounded text-white">+ New Event</Link>
					<div className="switch">
					</div>
				</div>
				{/* <!-- Modal --> */}
				<Modal className="modal fade" id="addContactModal"  show={addContact} onHide={setAddContact}>
					<div className="" role="document">
						<div className="">
							<form id="addContactModalTitle" onSubmit={onAddData}>
								<div className="modal-header">
									<h4 className="modal-title fs-20">New Event</h4>
									<button type="button" className="close" onClick={()=> setAddContact(false)} data-dismiss="modal"><span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
										{/* <div className="form-group">
												<label className="text-black font-w500">Bouncer</label>
												<div className="contact-name">

												</div>
											</div> */}
											<div className="form-group">
												<label className="text-black font-w500">Title</label>
												<div className="contact-name">
													<input type="text" id="c-name" className="form-control"  autocomplete="off"
														onChange={(event) => setTitle(event.target.value)}
														placeholder="Event title"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group">
												<label className="text-black font-w500">Date</label>
												<div className="contact-name">			
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DateTimePicker
															label=""
															inputVariant="outlined"
															inputProps={{width: '100%'}}
															style={{borderRadius: "1.375rem", width: '100%'}}
															value={date}
															onChange={setDate}
														/>
													</MuiPickersUtilsProvider>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group">
												<label className="text-black font-w500">Location</label>
												<div className="contact-name">
													{/* <input type="text" id="c-name" className="form-control"  autocomplete="off"
														onChange={(event) => getLocations(event.target.value)}
														placeholder="Location"
													/> */}
													 <GooglePlacesAutocomplete
													 selectProps={{
														locationOfEvent,
														onChange: setLocationOfEvent,
													  }}
													 />
												</div>
											</div>
											<div className="form-group">
												<label className="text-black font-w500">More info (optional)</label>
												<div className="contact-occupation">
													<textarea type="text"  id="c-occupation" autocomplete="off"
														onChange={(event) => setInfo(event.target.value)}
														className="form-control" placeholder="More info(optional)" 
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="text-black font-w500">Price</label>
												<div className="contact-occupation">
													<input type="number"  id="c-occupation" autocomplete="off"
														onChange={(event) => setPrice(event.target.value)}
														className="form-control" placeholder="Price" 
													/>
												</div>
											</div>
											<div className="form-group">
											<label className="text-black font-w500">Private</label>
												<div className="contact-occupation">
												<Switch onChange={() => setPrivateParty(!privateParty)} checked={privateParty} />
												</div>
												{privateParty && (
													<>
													<label className="text-black font-w500">Enter pin</label>
													<input type="text" id="c-name" className="form-control"  autocomplete="off"
													onChange={(event) => setPin(event.target.value)}
													placeholder="Enter pin"
												/>
												</>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									{/*<button type="submit" id="btn-edit" className="float-left btn btn-primary">Save</button>*/}
									<button type="button"  className="btn btn-info" onClick={()=> setAddContact(false)}> <i className="flaticon-delete-1"></i> Cancel</button>
									<button id="btn-add" className="btn btn-primary">Add</button> 
								</div>
							</form>
						</div>
					</div>
				</Modal>		
			</div>	
			<div className="tab-content">
				<div className="tab-pane fade show active" id="navpills-1" >
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<PerfectScrollbar className="row dz-scroll  loadmore-content searchable-items list" id="allContactListContent">
								<div className="items items-header-section">
								</div>
								
								{events.map((event, key) => {
									return (
										<>
											<div className="col-xl-3 col-xxl-4 col-lg-4 col-md-6 col-sm-6 items">
										<div className="card contact-bx item-content">
											<div className="card-header border-0">
													<div className="action-dropdown">
													<Dropdown className="">
														<Dropdown.Toggle variant="" as="div" className="i-false">
															<Link to={"#"} data-toggle="dropdown" aria-expanded="false">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																	<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																</svg>
															</Link>
														</Dropdown.Toggle>	
														<Dropdown.Menu className="dropdown-menu-right" alignRight={true}>
															<Dropdown.Item className="delete text-default">Edit event</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
												
											</div>
											<div className="card-body user-profile">
												<div className="media-body user-meta-info">
													<h6 className="fs-20 font-w500 my-1"><Link to={"/check-in/"+event[1]+'/'+event[0].checkcpc} className="text-black user-name" data-name="Engeline O’conner">{event[0].title}</Link></h6>
													<p className="fs-14 mb-3 user-work" data-occupation="UI Designer">${event[0].ticket_price / 100}</p>
													{/* <p className="fs-14 mb-3 user-work" data-occupation="UI Designer">Client of Degy</p> */}
													<ul>
														<li><Link to={"/check-in/"+event[1]+'/'+event[0].checkcpc}><i className="fa fa-user" aria-hidden="true"></i></Link></li>
														{/* <li><Link to={"./messages"}><i className="las la-sms"></i></Link></li> */}
														{/* <li><Link to={"#"}><i className="fa fa-video-camera" aria-hidden="true"></i></Link></li> */}
													</ul>
												</div>
											</div>
										</div>
									</div>
										</>
									)
								})}
							
							</PerfectScrollbar>
							{/* <div className="row">
								<div className="col-xl-12 d-flex justify-content-center">
									<Link to={"#"} className="btn btn-outline-primary dz-load-more"  onClick={() => onClick()}>
										Load More<span>&#187;</span>{" "}
										{refresh && <i className="fa fa-refresh"></i>}
									</Link>
								</div>
							</div> */}
						</TabPane>	
					</TabContent> 	
				</div>
			</div>		
		</Fragment>
	)
}
export default Events;