import {
    formatError,
    login,
    runLogoutTimer,
    saveToken,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import { getAuth, createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { addDoc, getFirestore, collection, where, getDocs, query, updateDoc, doc } from '@firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, title, compname, name, history) {
    const db = getFirestore()
const auth = getAuth();
    return (dispatch) => {
            createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          saveTokenInLocalStorage(userCredential._tokenResponse);
          runLogoutTimer(
              dispatch,
              userCredential._tokenResponse.expiresIn * 1000,
              history,
          );
          saveToken(userCredential.user.email)
    
          const q = query(collection(db, "users"), where("email", "==", email));

          const querySnapshot = await getDocs(q);
          console.log(querySnapshot)
          const cities = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            const x = [doc.data(), doc.id]
            cities.push(x)
          });
          
          const find = cities.filter((i) => i[0].email === email)
          console.log(find)
          const comp = {name: compname, id: uuidv4()}
          if (find.length === 0) {
            
            addDoc(collection(db, "users"), {
              email: email,
              title: title,
              name: name,
              org: [comp],
              accepted: true
            }).then((s) => {
                dispatch(confirmedSignupAction(userCredential.user));
                history.push('/dashboard');
                window.location.reload()
            })
          } else {
            const x = [...find[0]]
            const cityRef = doc(db, 'users', x[1]);
            updateDoc(cityRef, { accepted: true }, { merge: true }).then((r) => {
                dispatch(confirmedSignupAction(userCredential.user));
                history.push('/dashboard');
                window.location.reload()
            })
            }
      
		
     
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = formatError(error.message)
          dispatch(signupFailedAction(errorMessage));
        })
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    localStorage.removeItem('email');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, appVerifier, history, setSent, setCode) {
    const auth = getAuth();
    return (dispatch) => {
        console.log(email, appVerifier)
        console.log('called')
	    signInWithPhoneNumber(auth, "+1"+email, appVerifier)
        .then((confirmationResult) => {
            setSent(true)
            setCode(confirmationResult)
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
        }).catch((error) => {
            console.log(error)
          // Error; SMS not sent
          // ...
        });

    };
}

export function completeLogin(cred, history)  {
  
    return (dispatch) => {
    const user = cred.user;
    saveTokenInLocalStorage(cred._tokenResponse);
    runLogoutTimer(
      dispatch,
      cred._tokenResponse.expiresIn * 1000,
      history,
  );
  saveToken(cred.user.phoneNumber)
    dispatch(loginConfirmedAction(cred.user));
    history.push('/dashboard');
    window.location.reload()
    }
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
